
/* .p-tabview-nav{
    justify-content:flex-end
} */

.p-tabview .p-tabview-nav{
    
    background:#ffffff;
    border: 0px solid #ffffff;

}

.p-tabview .p-tabview-panels{
/* .p-fieldset{
    color: rgb(31, 31, 30) !important 

} */
}

.p-fieldset .p-fieldset-content{
padding:0 0 0 0 !important
}
.p-component{
font-family:unset
}

.p-accordion-content{
    --tw-bg-opacity: 1 !important;
    background-color:#F5F5F5 !important;
}

